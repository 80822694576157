import React from "react";
import Head from "./subComp/Head";

const Dine = () => {
  return <div>
    <Head/>
  </div>;
};

export default Dine;
