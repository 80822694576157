import React from "react";

const Map = () => {
  return (
    <div className="  bg-white  px-[2rem] py-[2rem]  flex flex-col items-center  gap-[2rem] lg:gap-[3rem]">
      <iframe
        title="Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3409.5484404017825!2d-82.22855362810219!3d27.019501153673396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c354c56143a78b%3A0x2bac33974960e705!2s544%20McCabe%20St%2C%20Port%20Charlotte%2C%20FL%2033953%2C%20USA!5e0!3m2!1sen!2sin!4v1704966768509!5m2!1sen!2sin"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        className="w-full rounded h-full bg-white"
      ></iframe>
    </div>
  );
};

export default Map;
